.speaker-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 15px;
  color: white; 
  cursor: pointer;
}

.speaker-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.5s;
}

.speaker-card:hover::before {
  left: 100%;
}

.speaker-card:hover {
  transform: translateY(-5px) scale(1.01);
  box-shadow: 0 15px 50px 0 rgba(31, 38, 135, 0.5);
}

.speaker-card-body {
  padding: 1rem;
}

.speaker-info {
  flex-grow: 1;
}

.speaker-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
}

.speaker-email {
  font-style: italic;
  font-size: 0.9rem;
  color: #cbd1d6;
  margin-bottom: 0.25rem;
}

.speaker-role {
  font-size: 0.9rem;
  color: #cbd1d6;
  margin-bottom: 0.25rem;
}

.speaker-actions {
  display: flex;
  align-items: center;
}

.speaker-actions button {
  margin-left: 0.5rem;
}

.speaker-edit-form {
  width: 100%;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.speaker-edit-form.active {
  opacity: 1;
  max-height: 500px; /* Yeterince büyük bir değer */
}

.delete-button {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  z-index: 2;
}

.speaker-card:hover .delete-button {
  opacity: 1;
}

.delete-button:hover {
  color: #ff6b6b;
}

@media (max-width: 576px) {
  .speaker-card-body {
    padding: 1rem;
  }

  .speaker-name {
    font-size: 1rem;
  }

  .speaker-email, .speaker-role {
    font-size: 0.8rem;
  }
}
/* ynileer */
.speaker-edit-form .form-control,
.speaker-edit-form .input-group {
  margin-bottom: 1rem !important;
}

.speaker-edit-form .input-group {
  display: flex;
  align-items: stretch;
}

.speaker-edit-form .input-group .form-control {
  margin-bottom: 0 !important;
}

.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  height: 100%;
}

.speaker-actions {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}