
.btn {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-size: 1em;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
 

 
  .btn:hover {
    background: rgba(255, 255, 255, 0.2);

   
  }

