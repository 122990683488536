.mp-main-page {
  background-color: #f8f9fa;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Sans-serif';
}

.mp-hero-section {
  background-color: #ffeaa7;
  border-radius: 0 0 50% 50% / 20px;
}

.mp-title {
  color: #2d3436;
}

.mp-subtitle {
  color: #636e72;
}

.mp-cta-button {
  background-color: #00b894;
  border-color: #00b894;
}

.mp-cta-button:hover {
  background-color: #00a383;
  border-color: #00a383;
}

.mp-feature-card {
  transition: transform 0.3s ease-in-out;
}

.mp-feature-card:hover {
  transform: translateY(-5px);
}



.mp-feature-emoji {
  font-size: 3rem;
  display: block;
  margin-bottom: 1rem;
}

.mp-conference-card {
  transition: transform 0.3s ease-in-out;
}

.mp-conference-card:hover {
  transform: scale(1.05);
}

.mp-conference-emoji {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}

.mp-conference-button {
  color: #00b894;
  border-color: #00b894;
}

.mp-conference-button:hover {
  background-color: #00b894;
  color: white;
}

.mp-cta-section {
  background-color: #81ecec;
  border-radius: 50% 50% 0 0 / 20px;
}

.mp-register-button {
  background-color: #00b894;
  border-color: #00b894;
}

.mp-register-button:hover {
  background-color: #00a383;
  border-color: #00a383;
}