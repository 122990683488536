@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap'); */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
    /* font-family: 'Open Sans', sans-serif; */
}

.L1section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: 0.5s;
  /* background: #d3cdce; */
  /* background: linear-gradient(to right, #636261, #d3cdce) no-repeat 0 0 / cover; */
}

.L1section .L1container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #FFF;
  box-shadow: 0 14px 28px rgba(0, 0, 0, .2), 0 10px 10px rgba(0, 0, 0, .2);
  overflow: hidden;
  border-radius: 10px;
}

.L1section .L1container .L1user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.L1section .L1container .L1user .L1imgBox {
  position: relative;
  width: 50%;
  height: 100%;
  transition: 0.5s;
}

.L1section .L1container .L1user .L1formBox {
  position: relative;
  width: 50%;
  height: 100%;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

.L1section .L1container .L1user .L1formBox form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

.L1section .L1container .L1user .L1formBox form input {
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.L1section .L1container .L1user .L1formBox form input:focus {
  border-color: #677eff;
  box-shadow: 0 0 8px rgba(103, 126, 255, 0.5);
  background: #fff;
}

.L1rounded-button {
  width: 100%;
  padding: 10px;
  background: #677eff;
  color: #FFF;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 25px;
  transition: 0.5s;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.L1rounded-button:hover {
  background: #4755b5;
}

.L1google-login-button {
  background: #fff;
  color: #333;
  border: 1px solid #ddd;

}

.L1google-login-button:hover {
  background: #bcdef5;
}

.L1google-icon {
  margin-right: 10px;
  font-weight: bold;
  color: #4374cf;
}

.L1sign-in-separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #777;
  margin: 15px 0;
}

.L1sign-in-separator::before,
.L1sign-in-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.L1sign-in-separator span {
  padding: 0 10px;
}

.L1remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.L1remember-me {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #555;
}

.L1remember-me input {
  margin-right: 5px;
}

.L1forgot-password {
  color: #677eff;
  font-size: 12px;
  text-decoration: none;
}

.L1section .L1container .L1user .L1formBox form .L1signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}

.L1section .L1container .L1user .L1formBox form .L1signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

.L1section .L1container .L1signupBox {
  pointer-events: none;
}

.L1section .L1container.L1active .L1signupBox {
  pointer-events: initial;
}

.L1section .L1container .L1signupBox .L1formBox {
  top: 100%;
}

.L1section .L1container.L1active .L1signupBox .L1formBox {
  top: 0;
}

.L1section .L1container .L1signupBox .L1imgBox {
  top: -100%;
  left: 0;
}

.L1section .L1container.L1active .L1signupBox .L1imgBox {
  top: 0;
}

.L1section .L1container .L1signinBox .L1formBox {
  top: 0;
}

.L1section .L1container.L1active .L1signinBox .L1formBox {
  top: 100%;
}

.L1section .L1container .L1signinBox .L1imgBox {
  top: 0;
}

.L1section .L1container.L1active .L1signinBox .L1imgBox {
  top: -100%;
}

.L1section .L1container .L1forgotPasswordBox {
  pointer-events: none;
}

.L1section .L1container.L1forgotPassword .L1forgotPasswordBox {
  pointer-events: initial;
}

.L1section .L1container .L1forgotPasswordBox .L1formBox {
  top: 100%;
}

.L1section .L1container.L1forgotPassword .L1forgotPasswordBox .L1formBox {
  top: 0;
}

.L1section .L1container .L1forgotPasswordBox .L1imgBox {
  top: -100%;
  left: 0;
}

.L1section .L1container.L1forgotPassword .L1forgotPasswordBox .L1imgBox {
  top: 0;
}

.L1section .L1container.L1forgotPassword .L1signinBox .L1formBox,
.L1section .L1container.L1forgotPassword .L1signinBox .L1imgBox {
  top: -100%;
}

@media (max-width: 991px) {
  .L1section .L1container {
    max-width: 400px;
  }
  
  .L1section .L1container .L1imgBox {
    display: none;
  }
  
  .L1section .L1container .L1user .L1formBox {
    width: 100%;
  }
}

.L1imgBox1 {
  background: #1b2dcc;
  background: linear-gradient(to right, #170acc, #3a4cf1) no-repeat 0 0 / cover;
}

.L1imgBox2 {
  background: #440657;
  background: linear-gradient(to right, #872bff, #440657) no-repeat 0 0 / cover;
}

.L1imgBox3 {
  background: #ff416c;
  background: linear-gradient(to right, #ff4b2b, #ff416c) no-repeat 0 0 / cover;
}
.L1imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.L1logo {
  width: 80%;
  height: 80%;
  max-width: 200px;
  max-height: 200px;
  transition: filter 0.3s ease-in-out;
}


.L1logo:hover {
  filter: brightness(1.1);
}
.error-message {
  color: red;
  margin-top: 10px;
}