
  
  .action-icon {

    font-size: 1.2rem;
    color: #ffffff;
    transition: color 0.3s ease, font-size 0.3s ease;
  }
  
  .action-icon:hover {
    color: #e0e0e0;
    font-size: 1.5rem;
  }
  
  .card-actions {
    margin-top: 15px;
  }
  
  .card-actions button {
    flex: 0 0 48%;
    margin: 0.25rem;
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .action-icon {
    margin-right: 0.25rem;
  }
  
  /* Existing styles */
  .moderator-panel {
    margin: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #333;
  }
  

.FaDownload:hover {
  
    color: #28b615f1;
    transition: color 0.3s ease;
   
}

.nav-tabs .nav-link.active {
    color: #3f51b5;
    border-bottom: 2px solid #3f51b5;
}



.badge {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
}

/* New styles for view switch */
.view-switch {
  display: inline-flex;
  background-color: #afaeaedc;
  border-radius: 9999px;
  padding: 2px;
}

.view-switch button {
  border: none;
  background: none;
  padding: 5px 10px;
  border-radius: 9999px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-switch button.active {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}


.buttonModalOk {
  background-color: #085B6A;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.buttonModalOk:hover {
  background-color: #0097B2;
  color: black;
}


.modal-button-cancel {
  background-color: #606264;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}



/* New styles for question-glass-card */
.question-glass-card {
  background: radial-gradient(circle at top right, rgba(255,255,255,0.2), transparent);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  margin: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  min-width: 230px;
}
  .question-glass-card:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .question-glass-card h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  
  
  .question-card-actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .question-card-actions button {
    flex: 1 1 calc(50% - 10px);
    margin: 5px;
    padding: 10px 5px;
    font-size: 0.8em;
    background-color: rgba(255,255,255,0.1);
    border: none;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .question-card-actions button:not(:last-child) {
    border-right: 1px solid rgba(255,255,255,0.2);
  }
  
  .question-card-actions button:hover {
    background-color: rgba(255,255,255,0.2);
  }
  .question-glass-badge {
   

    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
    margin-bottom: 30px;
    display: inline-block;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
    position: relative;
  }
  .question-glass-badge:hover {
    max-width: none;
    z-index: 2;
    background-color: rgb(82, 81, 81);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  /* transform: translateY(-100%); */
  top: 0;
  }
 
  .question-status-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
    font-weight: bold;
    opacity: 1;
    transition: all 0.5s ease;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 768px) {
    .question-glass-badge {
    max-width: 90px; /* Badge'in maksimum genişliğini sınırla */
  }
    
  }
  .question-approved .question-status-badge {
    background-color: rgba(76, 175, 80, 0.8);
    color: white;
  }
  
  .question-rejected .question-status-badge {
    background-color: rgba(244, 67, 54, 0.8);
    color: white;
  }
  .question-pending .question-status-badge {
    background-color: rgba(255, 193, 7, 0.8);
    color: black;
  }
  
  .question-pending {
    border: 2px solid rgba(255, 193, 7, 0.5);
  }
  
  .Denemememzzz {
    background-color: rgba(213, 218, 174, 0.781);
  }
  
  .question-pending::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1;
    z-index: -1;
    transition: opacity 0.3s ease;
    background: linear-gradient(45deg, #FFC107, transparent);
  }
  
  .question-pending:hover::before {
    opacity: 0.2;
  }
  .question-approved {
    border: 2px solid rgba(76, 175, 80, 0.5);
  }
  
  .question-rejected {
    border: 2px solid rgba(244, 67, 54, 0.5);
  }
  
  .question-approved::before,
  .question-rejected::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1;
    z-index: -1;
    transition: opacity 0.3s ease;
  }
  
  .question-approved::before {
    background: linear-gradient(45deg, #4CAF50, transparent);
  }
  
  .question-rejected::before {
    background: linear-gradient(45deg, #F44336, transparent);
  }
  
  .question-approved:hover::before,
  .question-rejected:hover::before {
    opacity: 0.2;
  }
  
  /* Ensure table cells are aligned vertically to the middle */
  .table td, .table th {
    vertical-align: middle;
  }


  /* Glass Table Styles */
  .moderator-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .moderator-table th,
  .moderator-table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .moderator-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  
  .moderator-table tr:hover {
    background-color: #f9f9f942;
  }
  
  .status-badge {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .status-onaylandı { background-color: #4CAF50; color: white; }
  .status-reddedildi { background-color: #F44336; color: white; }
  .status-beklemede { background-color: #FFC107; color: #333; }
  
  .action-icon {
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .action-approve { color: #4CAF50; }
  .action-reject { color: #F44336; }
  .action-edit { color: #2196F3; }
  .action-view { color: #9E9E9E; }
  
  .action-icon:hover {
    opacity: 0.8;
  }
  
  /* Diğer mevcut stiller korunacak */
  /* ... */
  
 /* Mevcut stillere eklenecek */
/* küçük ekranlar için */
@media (max-width: 768px) {
  .moderator-table {
    display: block;
  }

  .moderator-table thead {
    display: none;
  }

  .moderator-table tbody {
    display: block;
  }

  .moderator-table tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
  }

  .moderator-table td {
    display: block;
    text-align: center;
    padding: 0.5rem 0;
    border: none;
  }

  .moderator-table td::before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  .moderator-table td[data-label="Soran"] {
    font-style: italic;
    font-size: 0.9em;
  }

  .moderator-table td[data-label="Konuşmacı(lar)"] {
    font-size: 0.9em;
  }

  .moderator-table td:last-child {
    border-bottom: none;
  }

  .card-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
  }

  .moderator-table .card-actions button {
    flex: 1 1 70%;
    /* margin: 2px 0; */
    
    /* flex: 0 0 48%;*/
    margin: 0.25rem; 
    padding: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }

  .moderator-table .action-icon {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

/* Büyük ekranlar için mevcut stiller */
@media (min-width: 1025px) {
  .moderator-table .card-actions {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  .moderator-table .card-actions button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .moderator-table .card-actions button:last-child {
    margin-right: 0;
  }

  .moderator-table .action-icon {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

/* iPad Air ve benzer boyutlar için yeni medya sorgusu */
@media (min-width: 768px) and (max-width: 1024px) {
  .moderator-table tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .moderator-table thead {
    display: none;
  }

  .moderator-table td {
    flex: 0 0 100%;
    text-align: left;
    padding: 0.5rem 0;
    border: none;
  }

  .moderator-table td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 0.5rem;
  }

  .moderator-table .card-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
  }

  .moderator-table .card-actions button {
    flex: 0 0 48%;
    margin: 0.25rem 0;
    padding: 0.5rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  .moderator-table .action-icon {
    margin-right: 0.5rem;
  }
}

/* Responsive tasarım için medya sorguları */
@media (max-width: 768px) {
  .question-glass-card {
    padding: 15px;
    margin: 10px 5px;
  }

  .question-card-actions button {
    flex: 1 1 calc(50% - 6px);
    margin: 3px;
    padding: 8px 3px;
    font-size: 0.75em;
  }
}

@media (max-width: 480px) {
  .question-glass-card {
    padding: 12px;
    margin: 8px 3px;
  }

  .question-card-actions button {
    flex: 1 1 100%;
    margin: 2px 0;
  }
}


.modal-content {
  background-color: #fff;
  color: #333;
}

.modal-header, .modal-footer {
  border-color: #dee2e6;
}

.modal-title {
  color: #333;
}

.modal-footer .btn {
  color: #fff;
}

.modal-footer .btn-secondary {
  background-color: #6c757d;
}

.modal-footer .btn-primary {
  background-color: #007bff;
}

/* Updated Select styles */
.select__control {
  background-color: #fff !important;
  color: #333 !important;
}

.select__single-value, .select__input-container {
  color: #333 !important;
}

.select__menu {
  background-color: #fff !important;
}

.select__option {
  background-color: #fff !important;
  color: #333 !important;
}

.select__option--is-focused {
  background-color: #f0f0f0 !important;
}

.select__option--is-selected {
  background-color: #007bff !important;
  color: #fff !important;
}

/* Yeni stiller */
/* .self-read-icon {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff94;
  font-size: 2em;
  cursor: help;
} */

.self-read-icon {
  position: absolute;
  display: inline-block;
  
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff94;
  font-size: 2em;
  cursor: help;
}

.self-read-icon:hover::after {
  content: "Soruyu kendisi okumak istiyor";
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8em;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.take-floor {
  font-style: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.5em;

}
/* Tablo görünümü için ek stiller */
.moderator-table .self-read-icon {
  position: static;
  transform: none;
  margin-left: 5px;
}

.moderator-table .take-floor {
  display: flex;
  align-items: center;
  justify-content: center; /* Yatayda ortalama için eklendi */
}

.moderator-table .take-floor svg {
  margin-right: 5px;
}


/* Küçük ekranlar için ek stiller */
@media (max-width: 768px) {
  .moderator-table td {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0;
    border: none;
  }

  .moderator-table .take-floor {
    width: 100%;
    justify-content: center;
  }

  .moderator-table td:first-child {
    flex-direction: column;
  }

  .moderator-table .self-read-icon {
    margin-left: 0;
    margin-top: 5px;
  }
}

.filter-dropdown .form-control {
  color: #333 !important; /* Koyu gri renk */
}

.filter-select__control {
  color: #333 !important;
}

.filter-select__single-value {
  color: #333 !important;
}

.filter-select__input {
  color: #333 !important;
}



